<template>
  <div>
    <portal to="subheader_actions">
      <b-button
        v-b-modal.modal-form-quote-request
        @click="openModal"
        class="mr-2"
      >
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo
      </b-button>
    </portal>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-table
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(actions)="data">
                  <div class="h5 mb-0">
                    <b-button
                      size="sm"
                      variant="light"
                      v-b-modal.modal-form-quote-request
                      @click="edit(data)"
                    >
                      <b-icon icon="pencil-square"></b-icon>
                    </b-button>
                    <b-button @click="remove(data)" size="sm" variant="light">
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
                <template #cell(serie)="data"> SC-{{ data.item.id }} </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>
    <modal-form-quote-request
      :isOpen="showModal"
      v-show="loaded"
      v-on:close="
        showModal = false;
        currentObject = null;
      "
      :objForm="currentObject"
      v-on:toast="openToast"
      selectorToggle=".openModalFormColor"
      v-on:updated="onUpdated"
      v-on:created="onCreated"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ModalFormQuoteRequest from "./ModalFormQuoteRequest";

export default {
  data() {
    return {
      showModal: false,
      currentObject: {},
      currentPage: 1,
      loaded: false,
      perPage: 12,
      items: [],
      fields: [
        { key: "actions", label: "Acciones" },
        {
          key: "serie",
          sortable: true,
          label: "Número"
        },
        {
          key: "created_at",
          sortable: true,
          label: "Creación"
        },
        {
          key: "item_count",
          sortable: true,
          label: "Cantidad de items"
        }
      ]
    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    exportMaster() {
      ApiService.get(`api/report/master`).then((response) => {
        console.log(response.data);
        window.open(
          `https://api.trazo.maytok.com${response.data.URL}`,
          "_blank"
        );
      });
    },
    openToast({ message, object }) {
      this.$bvToast.toast(message, object);
    },
    listItems() {
      ApiService.get("api/quote-request", "").then(({ data }) => {
        this.items = data;
      });
    },
    edit(data) {
      this.currentObject = data.item;
      this.openModal();
    },
    openModal() {
      if (!this.loaded)
        setTimeout(() => {
          this.showModal = true;
        }, 50);
      else this.showModal = true;
      this.loaded = true;
    },
    remove({ item: { id } }) {
      Swal.fire({
        title: "",
        text: "¿Está seguro de eliminar la solicitud de cotización?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonClass: "btn btn-secondary",
        confirmButtonText: "Sí, estoy seguro.",
        confirmButtonClass: "btn btn-secondary",
        cancelButtonText: "Cancelar"
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          ApiService.delete(`/api/quote-request/${id}/`)
            .then(() => {
              this.openToast({
                message: "Se elimino correctamente la solicitud de cotización",
                object: {
                  title: `Éxito`,
                  variant: "success",
                  solid: true
                }
              });
              this.items = this.items.filter((el) => el.id !== id);
            })
            .catch(() => {
              this.openToast({
                message: "Ha ocurrido un error",
                object: {
                  title: `Error`,
                  variant: "danger",
                  solid: true
                }
              });
            });
        }
      });
    },
    onCreated(object) {
      this.items.unshift(object);
    },
    onUpdated(object) {
      this.items = this.items.map((data) => {
        const { id } = data;
        if (id === object.id) {
          return object;
        }
        return data;
      });
    }
  },
  created() {
    this.listItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Materiales" },
      { title: "Color" }
    ]);
  },
  components: {
    ModalFormQuoteRequest
  }
};
</script>
